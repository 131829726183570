<template>
    <div class="history">
        <el-row>
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="名称及编号:">
                        <el-input style="width: 200px" v-model="formInline.c_search" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select clearable style="width: 200px" v-model="formInline.c_status" placeholder="全部">
                            <el-option v-for="item in statusArray" :key="item.status" :label="item.name"
                                :value="item.status">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="init">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" :disabled="!permissionsAction.add"
                            @click="edit()">新建</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center', cursor: 'pointer' }" @row-click="cellClick" :data="tableData"
                    border style="width: 100%">
                    <el-table-column width="60" label="序号" type="index" :index="indexMethod"> </el-table-column>
                    <el-table-column prop="setup_number" width="220" label="编号">
                        <template #default="scope">
                            <el-button type="text">{{ scope.row.setup_number }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="setup_name" width="180" label="名称"></el-table-column>
                    <el-table-column prop="sort_amount" width="100" label="分类数"></el-table-column>
                    <el-table-column prop="belonging_to" width="80" label="所属类">
                        <template #default="scope">
                            <div v-if="scope.row.belonging_to == 1">硬装</div>
                            <div v-if="scope.row.belonging_to == 2">软装</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sonSort" label="分类"></el-table-column>
                    <el-table-column prop="picture" label="类目图片">
                        <template #default="scope">
                            <el-image style="width: 45px; height: 45px" :src="scope.row.picture"
                                :preview-src-list="[scope.row.picture]">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="component_amount" width="120" label="构件数"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template #default="scope">
                            <el-button :disabled="!permissionsAction.edit" @click="edit(scope.row)" type="text"
                                size="small">编辑</el-button>
                            <el-button v-if="scope.row.setup_status != 0" :disabled="!permissionsAction.enable"
                                @click.stop="del(scope.row, 1)" type="text" size="small">停用</el-button>
                            <el-button v-if="scope.row.setup_status == 0" :disabled="!permissionsAction.enable"
                                @click.stop="del(scope.row, 3)" type="text" size="small">启用</el-button>
                            <!--                            <el-button v-if="scope.row.setup_status != -1" @click.stop="del(scope.row,2)" type="text" size="small">删除</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.totalItems">
                    </el-pagination>
                </div>
            </div>
            <el-dialog title="新增/编辑分类" v-model="dialogFormVisible" width="580px" destroy-on-close @close="closeDialod"
                center>
                <el-form size="small" :model="ruleForm" :rules="rules" label-width="120px">
                    <el-form-item label="名称:">
                        <el-input style="width: 280px" v-model="ruleForm.name" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="所属类:">
                        <el-select clearable style="width: 280px" v-model="ruleForm.class" placeholder=" ">
                            <el-option v-for="item in setupBelonging_to" :key="item.status" :label="item.name"
                                :value="item.status">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类目图片:">
                        <el-upload :auto-upload="false" class="avatar-uploader" action="#" :show-file-list="false"
                            :on-change="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></el-icon>
                        </el-upload>
                    </el-form-item>
                    <el-form-item style="margin-top: 35px">
                        <el-button style="width: 100px" type="primary" @click="submit()">确认</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-row>
    </div>
</template>
<script>
import { getList, edit, del } from '../common/js/member';

export default {
    name: 'ComponentOne',
    data() {
        return {
            setupBelonging_to: [],
            imageUrl: '',
            selectArr: {
                setup_id1: '',
                material_id: '' //二级构件关联id
            },
            rules: [],
            statusArray: [],
            formInline: {
                c_search: '',
                c_status: ''
            },
            tableData: [],
            // 权限操作集合
            permissionsAction: {},
            page: {
                page: 1,
                pageSize: 10,
                totalItems: 0
            },
            ruleForm: {
                id: '',
                class: '',
                name: '',
                img: '',
            },
            dialogFormVisible: false
        };
    },
    created() {
        this.$nextTick(() => {
            this.init();
        });
    },
    methods: {
        handleUploadArtifactsMainPicture(file) {
            const isType = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$messageBox.error('上传图片只能是 jpg/png 格式!');
                this.ruleForm.img = '';
                this.imageUrl = '';
            } else if (!isLt10M) {
                this.$messageBox.error('上传图片大小不能超过 10MB!');
                this.ruleForm.img = '';
                this.imageUrl = '';
            } else {
                this.ruleForm.img = file;
                this.imageUrl = URL.createObjectURL(file.raw);
            }
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.ruleForm.img = file;
        },
        beforeAvatarUpload(file) {
            const isType = file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                this.ruleForm.img = '';
                this.imageUrl = '';
            } else if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                this.ruleForm.img = '';
                this.imageUrl = '';
            } else {
                this.ruleForm.img = file;
                this.imageUrl = URL.createObjectURL(file.raw);
            }
        },
        cellClick(row, column, event) {
            if (column.no != 6 && column.no != 8) {
                this.detailOne(row);
            }
        },
        detailOne(row) {
            this.$emit('detailOne', row);
        },
        submit() {
            if (!this.ruleForm.class) {
                this.$message.error('请选择所属类');
                return false;
            }
            if (!this.ruleForm.name) {
                this.$message.error('请输入名称');
                return false;
            }
            if (!this.ruleForm.img) {
                this.$message.error('请上传类目图片');
                return false;
            }
            let dt = new FormData();
            dt.append('setup_name', this.ruleForm.name);
            dt.append('belonging_to', this.ruleForm.class);
            if (this.ruleForm.img.raw) {
                dt.append('picture', this.ruleForm.img.raw);
            }
            if (this.ruleForm.id) {
                dt.append('id', this.ruleForm.id);
                edit(dt, 1).then(res => {
                    this.$message.success('成功');
                    this.dialogFormVisible = false;
                    this.closeDialod();
                    this.init();
                });
            } else {
                edit(dt).then(res => {
                    this.$message.success('成功');
                    this.dialogFormVisible = false;
                    this.closeDialod();
                    this.init();
                });
            }

        },
        closeDialod() {
            this.ruleForm = {
                id: '',
                class: '',
                name: '',
                img: '',
            };
            this.imageUrl = '';
        },
        edit(row) {
            if (row) {
                this.ruleForm = {
                    id: row.id,
                    class: row.belonging_to,
                    name: row.setup_name,
                    img: row.picture
                };
                this.imageUrl = row.picture;
            }
            this.dialogFormVisible = true;
        },
        del(row, type) {
            let title = '';
            if (type == 1) {
                title = '确认停用？';
            } else if (type == 2) {
                title = '确认删除？';
            } else {
                title = '确认启用？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let dt = {};
                if (row) {
                    dt.id = row.id;
                    dt.type = type;
                }
                del(dt).then(res => {
                    if (type == 1) {
                        this.$message.success('停用成功');
                    } else if (type == 2) {
                        this.$message.success('删除成功');
                    } else {
                        this.$message.success('启用成功');
                    }
                    this.init();
                });
            });
        },
        formatDate(value) {
            if (value) {
                let date = new Date(parseInt(value * 1000));
                let Y = date.getFullYear() + '-';
                let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + h + m + s;
            }
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },

        handleSizeChange(size) {
            this.page.pageSize = size;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        init() {
            let dt = {
                page: this.page.page,
                limit: this.page.pageSize,
                search: this.formInline.c_search,
                setup_status: this.formInline.c_status
            };
            getList(dt).then(res => {
                this.statusArray = res.result.setupStatus;
                this.setupBelonging_to = res.result.setupBelonging_to;
                this.tableData = res.result.list.data;
                let { Add: add, Delete: del, Edit: edit, Enable: enable } = res.result.Power;
                this.permissionsAction = {
                    add,
                    del,
                    edit,
                    enable
                };
                this.page.totalItems = res.result.list.total;
            });
        }
    }
};
</script>
<style>
.demo-input-label {
    display: inline-block;
    width: 130px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>