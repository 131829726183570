<template>
    <div class="componentThree">
        <el-row>
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formInline2" class="demo-form-inline">
                    <el-button @click="cancel2" size="small" style="margin-right: 10px">返回</el-button>
                    <el-form-item label="名称及编号:">
                        <el-input style="width: 200px" v-model="formInline2.c_search" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select  clearable  style="width: 200px" v-model="formInline2.c_status" placeholder="全部">
                            <el-option
                                    v-for="item in ThicknessStatus"
                                    :key="item.status"
                                    :label="item.name"
                                    :value="item.status">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="init2">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right;margin-right: 0">
                        <el-button style="width: 100px" type="primary" @click="edit2()">新建</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table
                        :header-cell-style="{background:'#F2F2F2',textAlign: 'center'}"
                        :cell-style="{ textAlign:'center',cursor:'pointer' }"
                        @row-click="cellClick"
                        :data="tableData2"
                        border
                        style="width: 100%">
                    <el-table-column
                            width="60"
                            label="序号"
                            type="index"
                            :index="indexMethod2">
                    </el-table-column>
                    <el-table-column prop="thickness_number" width="220" label="编号">
                        <template #default="scope">
                            <el-button type="text">{{scope.row.thickness_number}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="thickness_name" width="180" label="名称"></el-table-column>
                    <el-table-column prop="thickness_status" width="100" label="状态">
                        <template #default="scope">
                            <div v-if="scope.row.thickness_status == 1">正常</div>
                            <div v-if="scope.row.thickness_status == 0">停用</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="material_name" label="所属类"></el-table-column>
                    <el-table-column prop="picture" label="类目图片">
                        <template #default="scope">
                            <el-image
                                    style="width: 45px; height: 45px"
                                    :src="scope.row.picture"
                                    :preview-src-list="[scope.row.picture]">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="component_amount" label="构件数"></el-table-column>
<!--                    <el-table-column v-if="threeEdit" prop="thickness_size" label="尺寸">-->
                    <el-table-column prop="thickness_size" label="尺寸">
                        <template #default="scope">
                            <div>{{scope.row.thickness_size}}mm</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="180">
                        <template #default="scope">
                            <el-button @click="edit2(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button v-if="scope.row.thickness_status != 0" @click="del2(scope.row,1)" type="text" size="small">停用</el-button>
                            <el-button v-if="scope.row.thickness_status == 0" @click="del2(scope.row,3)" type="text" size="small">启用</el-button>
<!--                            <el-button v-if="scope.row.thickness_status != -1" @click="del2(scope.row,2)" type="text" size="small">删除</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right;margin-top: 25px">
                    <el-pagination
                            background
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                            :current-page="page2.page"
                            :page-sizes="[10, 15, 30, 35]"
                            :page-size="page2.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page2.totalItems">
                    </el-pagination>
                </div>
            </div>
            <el-dialog
                    title="新增/编辑分类"
                    v-model="dialogFormVisible2"
                    width="450px"
                    destroy-on-close
                    @close="closeDialod2"
                    center>
                <el-form size="small" :model="ruleForm2" :rules="rules2" label-width="120px">
                    <el-form-item label="名称:">
                        <el-input style="width: 200px" v-model="ruleForm2.name" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="尺寸:" class="specsInput">
<!--                    <el-form-item v-if="threeEdit" label="尺寸:" class="specsInput">-->
                        <el-input style="width: 200px" @input="valueChange" suffix-icon="el-icon-search" :disabled="ruleForm2.id != ''" v-model="ruleForm2.size" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="图片:">
                        <el-upload
                                :auto-upload="false"
                                class="avatar-uploader"
                                action="#"
                                :show-file-list="false"
                                :on-change="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></el-icon>
                        </el-upload>
                    </el-form-item>
                    <el-form-item style="margin-top: 35px">
                        <el-button style="width: 100px" type="primary" @click="submit2()">确认</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-row>

    </div>
</template>
<script>
    import { getList2,edit2,del2 } from "../common/js/member";
    export default {
        name:'ComponentThree',
        props:{
            setup_id:Number,
            material_id:Number,
            // threeEdit:Boolean
        },
        data () {
            return {
                imageUrl:'',
                rules2:[],
                statusArray:[],
                MaterialStatus:[],
                associateStatus:[],
                sameLevelList:[],
                ThicknessStatus:[],
                formInline2: {
                    c_search: '',
                    c_status: ''
                },
                tableData2: [],
                page2:{
                    page:1,
                    pageSize:10,
                    totalItems:0,
                },
                ruleForm2: {
                    id: '',
                    name: '',
                    size:'',
                    img:'',
                },
                dialogFormVisible2:false,
            }
        },
        created() {
            this.$nextTick(()=>{
                this.init2();
            })
        },
        methods:{
            beforeAvatarUpload(file){
                const isType = file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isType) {
                    this.$message.error('上传图片只能是 jpg/png 格式!');
                    this.ruleForm2.img = '';
                    this.imageUrl = '';
                } else if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    this.ruleForm2.img = '';
                    this.imageUrl = '';
                } else {
                    this.ruleForm2.img = file;
                    this.imageUrl = URL.createObjectURL(file.raw);
                }
            },
            cellClick(row, column, event){
                // if ((this.threeEdit == true && column.no < 7) || (this.threeEdit == false && column.no < 6)){
                if (column.no != 8 && column.no != 5){
                    // this.detail2(row);
                }
            },
            cancel2(){
                this.$emit('cancelThree');
            },
            detail2(row){
                this.$emit('detailThree',row);
                // console.log(row);
            },
            valueChange(e){
                let num = this.ruleForm2.size.indexOf('.');
                if (num > 0){
                    this.ruleForm2.size = this.ruleForm2.size.substr(0,num+3);
                }
            },
            submit2(){
                if (!this.ruleForm2.name){
                    this.$message.error('请输入名称');
                    return false;
                }
                // if (!this.ruleForm2.size && this.threeEdit == true){
                //     this.$message.error('请输入尺寸');
                //     return false;
                // }
                // if (parseInt(this.ruleForm2.size) == 0  && this.threeEdit == true){
                //     this.$message.error('尺寸不能为0');
                //     return false;
                // }
                if (!this.ruleForm2.img){
                    this.$message.error('请上传图片');
                    return false;
                }
                let dt = new FormData();
                dt.append('material_id',this.material_id);
                dt.append('thickness_name',this.ruleForm2.name);
                dt.append('thickness_size',this.ruleForm2.size ? Math.round(this.ruleForm2.size * 100) / 100 : 0);
                if (this.ruleForm2.img.raw){
                    dt.append('picture',this.ruleForm2.img.raw);
                }
                // let dt = {
                //     material_id:this.material_id,
                //     thickness_name:this.ruleForm2.name,
                //     thickness_size:this.ruleForm2.size ? Math.round(this.ruleForm2.size * 100) / 100 : 0
                // }
                // console.log(dt);
                if (this.ruleForm2.id){
                    dt.append('id',this.ruleForm2.id);
                    edit2(dt,1).then(res => {
                        this.$message.success('成功');
                        this.dialogFormVisible2 = false;
                        this.closeDialod2();
                        this.init2();
                    })
                    dt.id = this.ruleForm2.id;
                }else{
                    edit2(dt).then(res => {
                        this.$message.success('成功');
                        this.dialogFormVisible2 = false;
                        this.closeDialod2();
                        this.init2();
                    })
                }
            },

            closeDialod2(){
                this.ruleForm2 = {
                    id: '',
                    name: '',
                    size: '',
                    img:'',
                };
                this.imageUrl = '';
            },
            edit2(row){
                if (row){
                    this.ruleForm2 = {
                        id: row.id,
                        name: row.thickness_name,
                        size:row.thickness_size == 0 ? '' : row.thickness_size,
                        img:row.picture
                    }
                    this.imageUrl = row.picture;
                }
                this.dialogFormVisible2 = true;
            },

            del2(row,type){
                let title = '';
                if (type == 1){
                    title = '确认停用？'
                }else if (type == 2){
                    title = '确认删除？'
                }else {
                    title = '确认启用？'
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let dt = {};
                    if (row){
                        dt.id = row.id;
                        dt.type = type;
                    }
                    del2(dt).then(res => {
                        if (type == 1){
                            this.$message.success('停用成功');
                        }else if (type == 2){
                            this.$message.success('删除成功');
                        }else{
                            this.$message.success('启用成功');
                        }
                        this.init2();
                    })
                });
            },
            formatDate(value) {
                if (value){
                    let date = new Date(parseInt(value*1000));
                    let Y = date.getFullYear() + '-';
                    let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                    let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                    let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                    let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                    return Y + M + D + h + m + s;
                }
            },
            indexMethod2(index) {
                return this.page2.pageSize*(this.page2.page-1)+1+index;
            },
            handleSizeChange2(size) {
                this.page2.pageSize = size;
                this.init2();
            },
            handleCurrentChange2(page) {
                this.page2.page = page;
                this.init2();
            },

            init2(){
                let dt = {
                    page:this.page2.page,
                    limit:this.page2.pageSize,
                    material_id:this.material_id,
                    search: this.formInline2.c_search,
                    thickness_status: this.formInline2.c_status,
                }
                getList2(dt).then(res => {
                    this.showStatus = 3;
                    this.ThicknessStatus = res.result.ThicknessStatus;
                    this.tableData2 = res.result.list.data;
                    this.page2.totalItems = res.result.list.total;
                })
            },

        },

    }
</script>
<style>
    .demo-input-label {
        display: inline-block;
        width: 130px;
    }
    .componentThree .specsInput .el-input__suffix-inner .el-input__icon:after{
        content: 'mm ' !important;
        width: 26px;
        line-height: 30px;
        height: 35px;
    }
    .componentThree .specsInput .el-icon-search:before{
        display: none;
    }

</style>