<template>
    <div class="history">
        <el-row>
            <el-row v-if="relationShow1">
                        <div style="width: 100%">
                            <el-form size="small" :inline="true" :model="formInline1" class="demo-form-inline">
                                <el-button @click="cancel1()" size="small" style="margin-right: 10px">返回</el-button>
                                <el-form-item label="名称及编号:">
                                    <el-input style="width: 200px" v-model="formInline1.c_search" placeholder=" "></el-input>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select  clearable  style="width: 200px" v-model="formInline1.c_status" placeholder="全部">
                                        <el-option
                                                v-for="item in MaterialStatus"
                                                :key="item.status"
                                                :label="item.name"
                                                :value="item.status">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="init1">查询</el-button>
                                </el-form-item>
                                <el-form-item style="float: right;margin-right: 0">
                                    <el-button style="width: 100px" type="primary" @click="edit1()">新建</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="width: 100%">
                            <el-table
                                    :header-cell-style="{background:'#F2F2F2',textAlign: 'center'}"
                                    :cell-style="{ textAlign:'center',cursor:'pointer' }"
                                    @row-click="cellClick"
                                    :data="tableData1"
                                    border
                                    style="width: 100%">
                                <el-table-column
                                        width="60"
                                        label="序号"
                                        type="index"
                                        :index="indexMethod1">
                                </el-table-column>
                                <el-table-column prop="material_number" width="220" label="编号">
                                    <template #default="scope">
                                        <el-button type="text">{{scope.row.material_number}}</el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="material_name" label="名称"></el-table-column>
                                <el-table-column prop="material_status" label="状态">
                                    <template #default="scope">
                                        <div v-if="scope.row.material_status == 1">正常</div>
                                        <div v-if="scope.row.material_status == 0">停用</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="setup_name" label="所属类"></el-table-column>
                                <el-table-column prop="picture" label="类目图片">
                                    <template #default="scope">
                                        <el-image
                                                style="width: 45px; height: 45px"
                                                :src="scope.row.picture"
                                                :preview-src-list="[scope.row.picture]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="component_amount" label="构件数"></el-table-column>
                                <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="180">
                                    <template #default="scope">
                                        <el-button v-if="parseInt(confirmType.indexOf(scope.row.material_name)) < 0" @click="edit1(scope.row)" type="text" size="small">编辑</el-button>
                                        <el-button v-if="scope.row.material_status != 0" @click="del1(scope.row,1)" type="text" size="small">停用</el-button>
                                        <el-button v-if="scope.row.material_status == 0" @click="del1(scope.row,3)" type="text" size="small">启用</el-button>
<!--                                        <el-button v-if="scope.row.material_status != -1 && parseInt(confirmType.indexOf(scope.row.material_name)) < 0" @click="del1(scope.row,2)" type="text" size="small">删除</el-button>-->
                                        <el-button @click="relation1(scope.row)" type="text" size="small">关联</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="text-align: right;margin-top: 25px">
                                <el-pagination
                                        background
                                        @size-change="handleSizeChange1"
                                        @current-change="handleCurrentChange1"
                                        :current-page="page1.page"
                                        :page-sizes="[10, 15, 30, 35]"
                                        :page-size="page1.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="page1.totalItems">
                                </el-pagination>
                            </div>
                        </div>
                        <el-dialog
                                title="新增/编辑分类"
                                v-model="dialogFormVisible1"
                                width="450px"
                                destroy-on-close
                                @close="closeDialod1"
                                center>
                            <el-form size="small" :model="ruleForm1" :rules="rules1" label-width="120px">
                                <el-form-item label="名称:">
                                    <el-input style="width: 200px" v-model="ruleForm1.name" placeholder=" "></el-input>
                                </el-form-item>
                                <el-form-item label="图片:">
                                    <el-upload
                                            :auto-upload="false"
                                            class="avatar-uploader"
                                            action="#"
                                            :show-file-list="false"
                                            :on-change="beforeAvatarUpload">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                        <el-icon v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></el-icon>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item style="margin-top: 35px">
                                    <el-button style="width: 100px" type="primary" @click="submit1()">确认</el-button>
                                </el-form-item>
                            </el-form>
                        </el-dialog>
                    </el-row>
            <el-row v-else>
                        <div style="width: 100%">
                            <el-form size="small" :inline="true" :model="formInline11" class="demo-form-inline">
                                <el-button @click="cancel11" size="small" style="margin-right: 10px">返回</el-button>
                                <el-form-item label="名称及编号:">
                                    <el-input style="width: 200px" v-model="formInline11.c_search" placeholder=" "></el-input>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select  clearable  style="width: 200px" v-model="formInline11.c_status" placeholder="全部">
                                        <el-option
                                                v-for="item in associateStatus"
                                                :key="item.status"
                                                :label="item.name"
                                                :value="item.status">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="init11">查询</el-button>
                                </el-form-item>
                                <el-form-item style="float: right;margin-right: 0">
                                    <el-button style="width: 100px" type="primary" @click="edit11()">新建</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div style="width: 100%">
                            <el-table
                                    :header-cell-style="{background:'#F2F2F2',textAlign: 'center'}"
                                    :cell-style="{ textAlign:'center' }"
                                    :data="tableData11"
                                    border
                                    style="width: 100%">
                                <el-table-column
                                        width="60"
                                        label="序号"
                                        type="index"
                                        :index="indexMethod11">
                                </el-table-column>
                                <el-table-column prop="associate_number" width="220" label="编号"></el-table-column>
                                <el-table-column prop="material_name" label="名称"></el-table-column>
                                <el-table-column prop="associate_status" label="状态">
                                    <template #default="scope">
                                        <div v-if="scope.row.associate_status == 1">已关联</div>
                                        <div v-if="scope.row.associate_status == 0">未关联</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="father_name" label="对应类型"></el-table-column>
                                <el-table-column  label="更新时间">
                                    <template #default="scope">
                                        <el-col>{{formatDate(scope.row.create_time)}}</el-col>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="120">
                                    <template #default="scope">
                                        <el-button v-if="scope.row.associate_status == 1" @click="relevance(scope.row,1)" type="text" size="small">解除</el-button>
                                        <el-button v-if="scope.row.associate_status == 0" @click="relevance(scope.row,2)" type="text" size="small">关联</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="text-align: right;margin-top: 25px">
                                <el-pagination
                                        background
                                        @size-change="handleSizeChange11"
                                        @current-change="handleCurrentChange11"
                                        :current-page="page11.page"
                                        :page-sizes="[10, 15, 30, 35]"
                                        :page-size="page11.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="page11.totalItems">
                                </el-pagination>
                            </div>
                        </div>
                        <el-dialog
                                title="新增/编辑分类"
                                v-model="dialogFormVisible11"
                                width="470px"
                                destroy-on-close
                                @close="closeDialod11"
                                center>
                            <el-form size="small" :model="ruleForm11" :rules="rules11" label-width="140px">
                                <el-form-item label="名称:">
                                    <el-col>{{ruleForm11.name}}</el-col>
                                </el-form-item>
                                <el-form-item label="选择要关联的部件:">
                                    <el-select  clearable filterable style="width: 200px" v-model="ruleForm11.associate_id" placeholder="全部">
                                        <el-option
                                                v-for="item in sameLevelList"
                                                :disabled="item.id == selectArr.material_id"
                                                :key="item.id"
                                                :label="item.material_name"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="margin-top: 35px">
                                    <el-button style="width: 100px" type="primary" @click="submit11()">确认</el-button>
                                </el-form-item>
                            </el-form>
                        </el-dialog>
                    </el-row>
        </el-row>
    </div>
</template>
<script>
    import { getList1,edit1,del1,getList11,edit11,relevance } from "../common/js/member";
    export default {
        name:'ComponentOne',
        props:{
            setup_id:Number
        },
        data () {
            return {
                imageUrl:'',
                relationShow1:true,//二级的关联展示
                setupBelonging_to:[],
                selectArr:{
                    material_id:'',//二级构件关联id

                },
                rules1:[],
                rules11:[],
                statusArray:[],
                MaterialStatus:[],
                associateStatus:[],
                sameLevelList:[],
                ThicknessStatus:[],
                formInline1: {
                    c_search: '',
                    c_status: ''
                },
                formInline11: {
                    c_search: '',
                    c_status: ''
                },
                tableData1: [],
                tableData11: [],
                page1:{
                    page:1,
                    pageSize:10,
                    totalItems:0,
                },
                page11:{
                    page:1,
                    pageSize:10,
                    totalItems:0,
                },
                ruleForm1: {
                    id: '',
                    name: '',
                    img:'',
                },
                ruleForm11: {
                    name: '',
                    associate_id:'',
                },
                dialogFormVisible1:false,
                dialogFormVisible11:false,
            }
        },
        created() {
            this.$nextTick(()=>{
                this.init1();
            })
        },
        methods:{
            beforeAvatarUpload(file){
                const isType = file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isType) {
                    this.$message.error('上传图片只能是 jpg/png 格式!');
                    this.ruleForm1.img = '';
                    this.imageUrl = '';
                } else if (!isLt10M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    this.ruleForm1.img = '';
                    this.imageUrl = '';
                } else {
                    this.ruleForm1.img = file;
                    this.imageUrl = URL.createObjectURL(file.raw);
                }
            },
            confirm(name){
                if (parseInt(this.confirmType.indexOf(name)) < 0){
                    return true;
                }
            },
            cellClick(row, column, event){
                if (column.no != 5 && column.no != 7){
                    this.detail1(row);
                }
            },
            relevance(row,type){
                let title = '';
                if (type == 1){
                    title = '确认解除？'
                }else{
                    title = '确认关联？'
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let dt = {};
                    if (row){
                        dt.id = row.id;
                        dt.type = type;
                    }
                    relevance(dt).then(res => {
                        if (type == 1){
                            this.$message.success('解除成功');
                        }else{
                            this.$message.success('关联成功');
                        }
                        this.init11();
                    })
                });

            },
            relation1(row){
                this.selectArr.material_id = row.id;
                this.init11();
            },
            cancel1(){
                this.$emit('cancelTwo');
            },
            cancel11(){
                this.relationShow1 = true;
                this.init1();
            },
            detail1(row){
                this.$emit('detailTwo',row);
                // this.selectArr.material_id = row.id;
                // this.showStatus = 3;
            },
            submit1(){
                if (!this.ruleForm1.name){
                    this.$message.error('请输入名称');
                    return false;
                }
                if (!this.ruleForm1.img){
                    this.$message.error('请上传图片');
                    return false;
                }
                let dt = new FormData();
                dt.append('setup_id',this.setup_id);
                dt.append('material_name',this.ruleForm1.name);
                if (this.ruleForm1.img.raw){
                    dt.append('picture',this.ruleForm1.img.raw);
                }
                if (this.ruleForm1.id){
                    dt.append('id',this.ruleForm1.id);
                    // dt.id = this.ruleForm1.id;
                    edit1(dt,1).then(res => {
                        this.$message.success('成功');
                        this.dialogFormVisible1 = false;
                        this.closeDialod1();
                        this.init1();
                    })
                }else{
                    edit1(dt).then(res => {
                        this.$message.success('成功');
                        this.dialogFormVisible1 = false;
                        this.closeDialod1();
                        this.init1();
                    })
                }

            },
            submit11(){
                if (!this.ruleForm11.associate_id){
                    this.$message.error('请选择要关联的部件');
                    return false;
                }
                let dt = {
                    material_id:this.selectArr.material_id,
                    associate_id:this.ruleForm11.associate_id
                }
                edit11(dt).then(res => {
                    this.$message.success('成功');
                    this.dialogFormVisible11 = false;
                    this.closeDialod11();
                    this.init11();
                })
            },
            closeDialod1(){
                this.ruleForm1 = {
                    id: '',
                    name: '',
                    img:'',
                };
                this.imageUrl = '';
            },
            closeDialod11(){
                this.ruleForm11 = {
                    associate_id:'',
                    name: ''
                };
            },
            edit1(row){
                if (row){
                    this.ruleForm1 = {
                        id: row.id,
                        name: row.material_name,
                        img:row.picture
                    }
                    this.imageUrl = row.picture;
                }
                this.dialogFormVisible1 = true;
            },
            edit11(){
                let arr = this.tableData1;
                for (let i=0;i<arr.length;i++){
                    if (arr[i].id == this.selectArr.material_id){
                        this.ruleForm11.name = arr[i].material_name;
                    }
                }
                this.dialogFormVisible11 = true;
            },

            del1(row,type){
                let title = '';
                if (type == 1){
                    title = '确认停用？'
                }else if (type == 2){
                    title = '确认删除？'
                }else{
                    title = '确认启用？'
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let dt = {};
                    if (row){
                        dt.id = row.id;
                        dt.type = type;
                    }
                    del1(dt).then(res => {
                        if (type == 1){
                            this.$message.success('停用成功');
                        }else if (type == 2){
                            this.$message.success('删除成功');
                        }else{
                            this.$message.success('启用成功')
                        }
                        this.init1();
                    })
                });
            },
            formatDate(value) {
                if (value){
                    let date = new Date(parseInt(value*1000));
                    let Y = date.getFullYear() + '-';
                    let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
                    let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
                    let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
                    let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
                    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                    return Y + M + D + h + m + s;
                }
            },
            indexMethod1(index) {
                return this.page1.pageSize*(this.page1.page-1)+1+index;
            },
            indexMethod11(index) {
                return this.page11.pageSize*(this.page11.page-1)+1+index;
            },
            handleSizeChange1(size) {
                this.page1.pageSize = size;
                this.init1();
            },
            handleCurrentChange1(page) {
                this.page1.page = page;
                this.init1();
            },
            handleSizeChange11(size) {
                this.page11.pageSize = size;
                this.init11();
            },
            handleCurrentChange11(page) {
                this.page11.page = page;
                this.init11();
            },
            init1(){
                let dt = {
                    page:this.page1.page,
                    limit:this.page1.pageSize,
                    setup_id:this.setup_id,
                    search: this.formInline1.c_search,
                    material_status	: this.formInline1.c_status,
                }
                getList1(dt).then(res => {
                    this.MaterialStatus = res.result.MaterialStatus;
                    this.tableData1 = res.result.list.data;
                    this.page1.totalItems = res.result.list.total;
                })
            },
            init11(){
                let dt = {
                    page:this.page11.page,
                    limit:this.page11.pageSize,
                    setup_id:this.setup_id,
                    material_id:this.selectArr.material_id,
                    search: this.formInline11.c_search,
                    associate_status: this.formInline11.c_status,
                }
                getList11(dt).then(res => {
                    this.relationShow1 = false;
                    this.associateStatus = res.result.associateStatus;
                    this.tableData11 = res.result.associateList.data;
                    this.page11.totalItems = res.result.associateList.total;
                    this.sameLevelList = res.result.sameLevelList;
                })
            },
        },

    }
</script>
<style scoped>
    .demo-input-label {
        display: inline-block;
        width: 130px;
    }
</style>