import $http from '../../utils/http'

/**
 * 一级构件列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function getList(data) {
    return $http.get('/setup_list',data,'loadingDiv');
}

/**
 * 一级构件编辑新增
 * @param data
 * @returns {Promise<unknown>}
 */
export function edit(data,type) {
    if (type){
        return $http.post('/change_setup',data,'loadingDiv','multipart/form-data');
    }else{
        return $http.post('/add_setup',data,'loadingDiv','multipart/form-data');
    }
}

/**
 * 一级构件删除和停用
 * @param data
 * @returns {Promise<unknown>}
 */
export function del(data) {
    return $http.post('/deactivate_setup',data,'loadingDiv');
}

/**
 * 二级构件列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function getList1(data) {
    return $http.get('/material_list',data,'loadingDiv');
}
/**
 * 二级构件编辑新增
 * @param data
 * @returns {Promise<unknown>}
 */
export function edit1(data,type) {
    if (type){
        return $http.post('/change_material',data,'loadingDiv','multipart/form-data');
    }else{
        return $http.post('/add_material',data,'loadingDiv','multipart/form-data');
    }
}

/**
 * 二级构件删除和停用
 * @param data
 * @returns {Promise<unknown>}
 */
export function del1(data) {
    return $http.post('/deactivate_material',data,'loadingDiv');
}


/**
 * 二级构件列表关联
 * @param data
 * @returns {Promise<unknown>}
 */
export function getList11(data) {
    return $http.get('/associate_list',data,'loadingDiv');
}
/**
 * 二级构件关联新增
 * @param data
 * @returns {Promise<unknown>}
 */
export function edit11(data) {
    return $http.post('/add_associate',data,'loadingDiv');
}

/**
 * 二级构件关联解除
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function relevance(data) {
    return $http.post('/change_associate',data,'loadingDiv');
}

/**
 * 三级构件列表
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function getList2(data) {
    return $http.get('/thickness_list',data,'loadingDiv');
}

/**
 * 三级构件新增编辑
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function edit2(data,type) {
    if (type){
        return $http.post('/change_thickness',data,'loadingDiv','multipart/form-data');
    }else{
        return $http.post('/add_thickness',data,'loadingDiv','multipart/form-data');
    }
}

/**
 * 三级构件删除停用
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function del2(data) {
    return $http.post('/deactivate_thickness',data,'loadingDiv');

}

/**
 * 通配详情
 * @param data
 */
export function getList3(data) {
    return $http.get('/deploy_details',data,'loadingDiv');
}

/**
 * 新增编辑通配
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function edit3(data) {
    if (data.id){
        return $http.post('/change_deploy',data,'loadingDiv');
    }else{
        return $http.post('/add_deploy',data,'loadingDiv');
    }
}

/**
 * 品牌配置列表
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function getList31(data) {
    return $http.get('/brandDeploy_list',data,'loadingDiv');
}

/**
 * 新增品牌配置
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function addList(data) {
    return $http.post('/add_brandDeploy',data,'loadingDiv');
}

/**
 * 删除品牌配置
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function delList(data) {
    return $http.post('/del_brandDeploy',data,'loadingDiv');
}

/**
 * 品牌配置列表
 * @param data
 */
export function getTable(data) {
    return $http.get('/brandAssociate_list',data,'loadingDiv');
}

/**
 * 品牌配置新增编辑
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function editTable(data) {
    if (data.id){
        return $http.post('/change_brandAssociate',data,'fourLoading');
    }else{
        return $http.post('/add_brandAssociate',data,'fourLoading');
    }
}

/**
 * 解除关联
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function relieve(data) {
    return $http.post('/dismiss_brandAssociate',data,'loadingDiv');
}