<template>
    <div class="componentFour" style="width: 100%">
        <el-row style="width: 100%;">
            <div style="text-align: center;width: 100%">
                <el-button @click="cancel" size="small" style="margin-right: 10px;float: left">返回</el-button>
                {{thickness_name}}
            </div>
            <div style="margin-top: 15px;width: 100%">
                <el-radio-group  size="medium" v-model="type">
                    <el-radio-button style="min-width: 100px" v-for="(item,index) in typeArray" :key="index" :label="item.id">{{item.name}}</el-radio-button>
                </el-radio-group>
            </div>
            <el-row v-if="type == 1">
                <div style="width: 100%;margin-top: 25px" >
                    <el-form size="small" label-width="150px" class="demo-form-inline">
<!--                        <el-form-item label="编号:" required>-->
<!--                            <el-col>-->
<!--                                {{wildcard.deploy_number}}-->
<!--                            </el-col>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="名称:" required>-->
<!--                            <el-col>-->
<!--                                {{wildcard.thickness_name}}-->
<!--                            </el-col>-->
<!--                        </el-form-item>-->
                    </el-form>
                </div>
                <div style="margin-top: 15px">
                    <el-radio-group @change="getList()" size="medium" v-model="material_id">
                        <el-radio-button style="min-width: 100px" v-for="(item,index) in top_list" :key="index" :label="item.id">{{item.material_name}}</el-radio-button>
                    </el-radio-group>
                </div>
                <el-row style="width: 100%;margin-top: 25px">
                <span style="display: inline-block;width: 450px;float: left">
                    <el-form size="small" label-width="150px" class="demo-form-inline">
                        <el-form-item label="选择墙板品牌:">
                            <el-row>
                                <el-select  clearable  v-model="searchId" placeholder=" ">
                                        <el-option
                                                v-for="item in search_list"
                                                :key="item.b_id"
                                                :label="item.b_name"
                                                :value="item.b_id">
                                        </el-option>
                                </el-select>
                                <el-button @click="addList" style="margin-left:5px">添加</el-button>
                            </el-row>
                        </el-form-item>

                        <el-form-item label="墙板品牌:">
                            <el-tag v-for="(item,index) in list" :type="item.brand_id == tableId ? danger : ''" :key="index" @click="clickTag(item)" closable @close="closeTag(item)">{{item.b_name}}</el-tag>
                        </el-form-item>

                    </el-form>
                </span>
                <span v-if="tableId != ''" style="display: inline-block;width: calc(100% - 476px);border-left: 1px solid #dcdfe6;padding-left: 25px;">
                    <div>
                        <div style="width: 100%">
                        <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline">
                            <el-form-item label="对应品牌">
                                <el-select  clearable  style="width: 100px" v-model="formInline.serach_brand_id" placeholder="全部">
                                    <el-option
                                            v-for="item in searchBrandList"
                                            :key="item.b_id"
                                            :label="item.b_name"
                                            :value="item.b_id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="状态">
                                <el-select  clearable  style="width: 100px" v-model="formInline.status" placeholder="全部">
                                    <el-option
                                            v-for="item in brandAssociateStatus"
                                            :key="item.status"
                                            :label="item.name"
                                            :value="item.status">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="clickTag()">查询</el-button>
                            </el-form-item>
                            <el-form-item style="float: right;margin-right: 0">
                                <el-button type="primary" @click="addTable">新建</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="width: 100%">
                        <el-table
                                :header-cell-style="{background:'#F2F2F2',textAlign: 'center'}"
                                :cell-style="{ textAlign:'center' }"
                                :data="associateList"
                                border
                                style="width: 100%">
                            <el-table-column
                                    width="60"
                                    label="序号"
                                    type="index"
                                    :index="indexMethod">
                            </el-table-column>
                            <el-table-column prop="brand_name" label="品牌"></el-table-column>
                            <el-table-column prop="status" width="100" label="状态">
                                <template #default="scope">
                                    <el-col v-if="scope.row.status == 1">已关联</el-col>
                                    <el-col v-if="scope.row.status == 0">未关联</el-col>
                                </template>
                            </el-table-column>
                            <el-table-column prop="b_name" label="对应品牌"></el-table-column>
                            <el-table-column prop="size" label="尺寸">
                                <template #default="scope">
                                    <el-col>{{scope.row.size}}mm</el-col>
                                </template>
                            </el-table-column>
                            <el-table-column prop="thickness_name" label="关联物品类"></el-table-column>
                            <el-table-column
                                    fixed="right"
                                    label="操作">
                                <template #default="scope">
                                    <el-button @click="addTable(scope.row)" type="text" size="small">编辑</el-button>
                                    <el-button v-if="scope.row.status == 1" @click="relieve(scope.row,1)" type="text" size="small">解除</el-button>
                                    <el-button v-if="scope.row.status == 0" @click="relieve(scope.row,2)" type="text" size="small">关联</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: right;margin-top: 25px">
                            <el-pagination
                                    background
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="page.page"
                                    :page-sizes="[10, 15, 30, 35]"
                                    :page-size="page.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="page.totalItems">
                            </el-pagination>
                        </div>
                    </div>
                    </div>
                </span>
                </el-row>
                <el-dialog
                        title="新建/编辑关联品牌"
                        v-model="dialogFormVisible"
                        width="700px"
                        destroy-on-close
                        @close="closeDialod"
                        center>
                    <el-form class="fourLoading" size="small" :model="ruleForm1" :rules="rules1" label-width="150px">
                        <el-form-item label="关联的:">
                            <el-select  clearable disabled v-model="ruleForm1.material_id" style="width: 150px" placeholder="全部">
                                <el-option
                                        v-for="item in materialArr"
                                        :key="item.id"
                                        :label="item.material_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select  clearable v-model="ruleForm1.m_tid" style="width: 150px;margin-left: 15px" placeholder="请关联配件">
                                <el-option
                                        v-for="item in thicknessList"
                                        :key="item.id"
                                        :label="item.thickness_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择要关联的品牌:">
<!--                            :disabled="item.associate_status == 0"-->

                            <el-select filterable clearable v-model="ruleForm1.construct_id" style="width: 315px" placeholder="全部">
                                <el-option
                                        v-for="item in constructList"
                                        :key="item.b_id"
                                        :label="item.b_name"
                                        :value="item.b_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-radio style="width: 100%" v-model="ruleForm1.type" label="1">同墙板厚度一样的尺寸</el-radio>
                            <el-radio  style="width: 100%" v-model="ruleForm1.type" label="2">厚度在一个取值范围的尺寸</el-radio>
                        </el-form-item>
                        <el-form-item v-if="ruleForm1.type == 2" label="固定厚度尺寸:">
                            <el-input v-model="ruleForm1.size" @input="valueChange1" class="specsInput" suffix-icon="el-icon-search" type="number" style="width: 315px"></el-input>
                        </el-form-item>
                        <el-form-item v-if="ruleForm1.type == 2" label="厚度范围区间值:">
                            <el-input v-model="ruleForm1.start" @input="valueChange2" class="specsInput" suffix-icon="el-icon-search" type="number" style="width: 150px"></el-input>
                            <span style="display: inline-block;text-align: center;width: 15px">-</span>
                            <el-input v-model="ruleForm1.end" @input="valueChange3" class="specsInput" suffix-icon="el-icon-search" type="number" style="width: 150px"></el-input>
                            <el-row style="font-size: 12px;color: #909693">
                                注：固定厚度，厚度范围区间，填其中一项即可，根据构件实际匹配情况来填
                            </el-row>
                        </el-form-item>
                        <el-form-item style="margin-top: 35px">
                            <el-button style="width: 100px" type="primary" @click="submit1()">确认</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-row>
            <el-row v-if="type == 2" style="width: 100%">
                <el-col class="type2" :offset="6" :span="12">
                    <el-form size="small" :model="ruleForm" ref="ruleForm" :rules="rules" label-width="150px" style="width: 100%;margin-top: 35px">
                        <el-form-item v-if="ruleForm.deploy_number" label="编号:" required>
                            <el-col>{{ruleForm.deploy_number}}</el-col>
                        </el-form-item>
                        <el-form-item label="名称:" required>
                            <el-col>{{ruleForm.thickness_name}}</el-col>
                        </el-form-item>

                        <el-form-item label="选择墙板品牌:">
                            <el-select @change="init()" clearable style="width: 240px" v-model="ruleForm.brand_id" placeholder=" ">
                                <el-option
                                        v-for="i in brandList"
                                        :key="i.b_id"
                                        :label="i.b_name"
                                        :value="i.b_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-row v-if="ruleForm.brand_id">
                            <el-row v-for="(item,index) in ruleForm.grassroots" :key="index">
                                <el-form-item label="可关联的基层:">
                                    <el-row>
                                        <el-select @change="changSelect(1,index,true)" clearable  v-model="item.grassroots_id" placeholder=" ">
                                            <el-option
                                                    v-for="i in grassroots"
                                                    :key="i.id"
                                                    :disabled="findDisable(1,i)"
                                                    :label="i.thickness_name"
                                                    :value="i.id">
                                            </el-option>
                                        </el-select>
                                        <el-button v-if="index == 0" @click="addSpace(1)" style="margin-left:5px;padding: 10px"><i class="el-icon-plus"></i></el-button>
                                        <el-button v-else @click="addSpace(1,index)" style="margin-left:5px;padding: 10px"><i class="el-icon-minus"></i></el-button>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="选择要关联的部件:">
                                    <el-row>
                                        <el-select @change="changSelect1(1,index)" style="width: 240px" clearable  v-model="item.construct_id" placeholder=" ">
                                            <el-option
                                                    v-for="it in item.grassroots_part_list"
                                                    :key="it.id"
                                                    :label="it.name"
                                                    :value="it.id">
                                            </el-option>
                                        </el-select>
                                    </el-row>
                                </el-form-item>
                                <el-form-item v-if="item.construct_id" label="基层规格:"  :prop="'grassroots.'+index+'.format'" :rules="rules.format[0]">
                                    <el-input disabled style="width: 240px" v-model="item.format" placeholder=" "></el-input>
                                </el-form-item>
                            </el-row>
                            <el-divider></el-divider>

                            <el-row v-for="(item,index) in ruleForm.keel" :key="index">
                                <el-form-item label="可关联的龙骨:">
                                    <el-row>
                                        <el-select  @change="changSelect(2,index,true)" clearable  v-model="item.keel_id" placeholder=" ">
                                            <el-option
                                                    v-for="i in keel"
                                                    :key="i.id"
                                                    :disabled="findDisable(2,i)"
                                                    :label="i.thickness_name"
                                                    :value="i.id">
                                            </el-option>
                                        </el-select>
                                        <el-button v-if="index == 0" @click="addSpace(2)" style="margin-left:5px;padding: 10px"><i class="el-icon-plus"></i></el-button>
                                        <el-button v-else @click="addSpace(2,index)" style="margin-left:5px;padding: 10px"><i class="el-icon-minus"></i></el-button>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="选择要关联的部件:">
                                    <el-row>
                                        <el-select @change="changSelect1(2,index)" style="width: 240px" clearable  v-model="item.construct_id" placeholder=" ">
                                            <el-option
                                                    v-for="it in item.keel_part_list"
                                                    :key="it.id"
                                                    :label="it.name"
                                                    :value="it.id">
                                            </el-option>
                                        </el-select>
                                    </el-row>
                                </el-form-item>
                                <el-form-item v-if="item.construct_id" label="龙骨规格:" :prop="'keel.'+index+'.format'" :rules="rules.format[1]">
                                    <el-input disabled style="width: 240px" v-model="item.format" placeholder=" "></el-input>
                                </el-form-item>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row v-for="(item,index) in ruleForm.structure" :key="index">
                                <el-form-item label="可关联的结构件:">
                                    <el-row>
                                        <el-select @change="changSelect(3,index,true)" clearable  v-model="item.structure_id" placeholder=" ">
                                            <el-option
                                                    v-for="i in structure"
                                                    :key="i.id"
                                                    :disabled="findDisable(3,i)"
                                                    :label="i.thickness_name"
                                                    :value="i.id">
                                            </el-option>
                                        </el-select>
                                        <el-button v-if="index == 0" @click="addSpace(3)" style="margin-left:5px;padding: 10px"><i class="el-icon-plus"></i></el-button>
                                        <el-button v-else @click="addSpace(3,index)" style="margin-left:5px;padding: 10px"><i class="el-icon-minus"></i></el-button>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="选择要关联的部件:">
                                    <el-row>
                                        <el-select @change="changSelect1(3,index)" style="width: 240px" clearable  v-model="item.construct_id" placeholder=" ">
                                            <el-option
                                                    v-for="it in item.structure_part_list"
                                                    :key="it.id"
                                                    :label="it.name"
                                                    :value="it.id">
                                            </el-option>
                                        </el-select>
                                    </el-row>
                                </el-form-item>
                                <el-form-item v-if="item.construct_id" label="结构件规格:"  :prop="'structure.'+index+'.format'" :rules="rules.format[2]">
                                    <el-input disabled style="width: 240px" v-model="item.format" placeholder=" "></el-input>
                                </el-form-item>
                            </el-row>
                            <el-form-item style="margin-top: 15px">
                                <el-button type="primary" @click="submit('ruleForm')">确认</el-button>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>
<script>
    import { getList3,edit3,getList31,addList,delList,getTable,editTable,relieve } from "../common/js/member";
    export default {
        name:'ComponentThree',
        props:{
            thickness_id:Number,
            thickness_name:String
        },
        data () {
            return {
                searchId:'',
                typeArray:[
                    {
                        id:1,
                        name:'品牌配置'
                    },
                    {
                        id:2,
                        name:'默认配置'
                    }
                ],
                danger:'danger',
                type:2,
                dialogFormVisible:false,
                wildcard:{
                    deploy_number:'',
                    thickness_name:'',
                },
                ruleForm:{
                    id:'',
                    deploy_number:'',
                    thickness_id:'',
                    brand_id:'',
                    grassroots:[
                        {
                            grassroots_id:'',
                            grassroots_part_list:[],
                            construct_id:'',
                            format:'',
                        }
                    ],
                    keel:[
                        {
                            keel_id:'',
                            keel_part_list:[],
                            construct_id:'',
                            format:'',
                        }
                    ],
                    structure:[
                        {
                            structure_id:'',
                            structure_part_list:[],
                            construct_id:'',
                            format:'',
                        }
                    ],
                    thickness_name:'',
                },
                rules:{
                    format: [
                        {required: true, message: '请输入基层规格', trigger: 'blur'},
                        {required: true, message: '请输入龙骨规格', trigger: 'blur'},
                        {required: true, message: '请输入结构件规格', trigger: 'blur'},
                    ],
                },
                rules1:{

                },
                grassroots:[],
                keel:[],
                structure:[],
                top_list:[],
                search_list:[],
                list:[],
                material_id:'',
                page:{
                    page:1,
                    pageSize:10,
                    totalItems:0,
                },
                tableId:'',
                brandAssociateStatus:[],
                constructList:[],
                brandList:[],
                associateList:[],
                searchBrandList:[],
                materialArr:[],
                thicknessList:[],
                formInline: {
                    serach_brand_id: '',
                    status: ''
                },
                ruleForm1: {
                    id: '',
                    type:"1",
                    material_id:'',
                    construct_id:'',
                    beforeConstruct_id:'',
                    m_tid:'',
                    size:'',
                    start:'',
                    end:'',
                },





            }
        },
        created() {
            this.getList(1);
            this.init();
        },
        watch:{
            material_id(){
                this.tableId = '';
                this.searchId = '';
            }
        },
        methods:{
            findDisable(type,row){
                if (type == 1){
                    let t = false;
                    this.ruleForm.grassroots.forEach((ele)=>{
                        if (row.id == ele.grassroots_id){
                            t = true;
                        }
                    })
                    return t;
                }
                if (type == 2){
                    let t = false;
                    this.ruleForm.keel.forEach((ele)=>{
                        if (row.id == ele.keel_id){
                            t = true;
                        }
                    })
                    return t;
                }
                if (type == 3){
                    let t = false;
                    this.ruleForm.structure.forEach((ele)=>{
                        if (row.id == ele.structure_id){
                            t = true;
                        }
                    })
                    return t;
                }
            },
            changSelect(type,index,clear){
                let _this = this;
                if (type == 1){
                    if (clear){
                        _this.ruleForm.grassroots[index].construct_id = '';
                        _this.ruleForm.grassroots[index].grassroots_part_list = [];
                        _this.ruleForm.grassroots[index].format = '';
                    }
                    this.grassroots.forEach((ele) => {
                        if (_this.ruleForm.grassroots[index].grassroots_id == ele.id){
                            _this.ruleForm.grassroots[index].grassroots_part_list = ele.grassroots_part_list;
                        }
                    });
                }else if (type ==  2){
                    if (clear){
                        _this.ruleForm.keel[index].construct_id = '';
                        _this.ruleForm.keel[index].keel_part_list = [];
                        _this.ruleForm.keel[index].format = '';
                    }
                    this.keel.forEach((ele) => {
                        if (_this.ruleForm.keel[index].keel_id == ele.id) {
                            _this.ruleForm.keel[index].keel_part_list = ele.keel_part_list;
                        }
                    });
                }else {
                    if (clear){
                        _this.ruleForm.structure[index].construct_id = '';
                        _this.ruleForm.structure[index].structure_part_list = [];
                        _this.ruleForm.structure[index].format = '';
                    }
                    this.structure.forEach((ele) => {
                        if (_this.ruleForm.structure[index].structure_id == ele.id) {
                            _this.ruleForm.structure[index].structure_part_list = ele.structure_part_list;
                        }
                    });
                }
            },

            changSelect1(type,index){
                if (type == 1){
                    this.ruleForm.grassroots[index].grassroots_part_list.forEach((ele)=>{
                        if (ele.id == this.ruleForm.grassroots[index].construct_id){
                            this.ruleForm.grassroots[index].format = ele.default_size;
                        }
                    })
                }
                if (type == 2){
                    this.ruleForm.keel[index].keel_part_list.forEach((ele)=>{
                        if (ele.id == this.ruleForm.keel[index].construct_id){
                            this.ruleForm.keel[index].format = ele.default_size;
                        }
                    })
                }
                if (type == 3){
                    this.ruleForm.structure[index].structure_part_list.forEach((ele)=>{
                        if (ele.id == this.ruleForm.structure[index].construct_id){
                            this.ruleForm.structure[index].format = ele.default_size;
                        }
                    })
                }

            },
            relieve(row,type){
                let title = '';
                if (type == 1){
                    title = '确认解除？';
                }else{
                    title = '确认关联？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let dt = {
                        id:row.id,
                        type:type
                    };
                    relieve(dt).then(res => {
                        this.$message.success(res.msg);
                        this.clickTag();
                    })
                })
            },
            addTable(row){
                if (row){
                    this.ruleForm1.id = row.id;
                    this.ruleForm1.construct_id = row.associate_bid ? parseInt(row.associate_bid) : '';
                    this.ruleForm1.beforeConstruct_id = row.associate_bid ? parseInt(row.associate_bid) : '';
                    this.ruleForm1.m_tid = row.m_tid ? parseInt(row.m_tid) : '';
                    if (row.type == 1){
                        this.ruleForm1.type = '1';
                    }
                    if (row.type == 2){
                        this.ruleForm1.type = '2';
                        this.ruleForm1.size = row.size ? parseInt(row.size) : '';
                    }
                    if (row.type == 3){
                        this.ruleForm1.type = '2';
                        let arr = row.size.split('-');
                        this.ruleForm1.start = arr[0];
                        if (arr.length > 1){
                            this.ruleForm1.end = arr[1];
                        }else{
                            this.ruleForm1.end = arr[0];
                        }
                    }
                }
                this.dialogFormVisible = true;
            },
            submit1(){
                if (this.ruleForm1.m_tid == ''){
                    this.$message.error('请关联配件');
                    return false;
                }
                if (this.ruleForm1.construct_id == ''){
                    this.$message.error('请选择要关联的品牌');
                    return false;
                }
               let dt = {
                   thickness_id:this.thickness_id,
                   material_id:this.material_id,
                   brand_id:this.tableId,
                   m_tid:this.ruleForm1.m_tid,
                   associate_bid:this.ruleForm1.construct_id
               }
               if (this.ruleForm1.type == '1'){
                   dt.type = 1;
               }else{
                   if (this.ruleForm1.start){
                       dt.type = 3;
                       if (this.ruleForm1.start == this.ruleForm1.end){
                           dt.size = this.ruleForm1.start;
                       }else{
                           dt.size = this.ruleForm1.end ? this.ruleForm1.start+'-'+this.ruleForm1.end : this.ruleForm1.start;
                       }
                   }else{
                       dt.type = 2;
                       dt.size = this.ruleForm1.size;
                   }
               }
               if (this.ruleForm1.id){
                   dt.id = this.ruleForm1.id;
               }
               editTable(dt).then(res => {
                   this.$message.success(res.msg);
                   this.closeDialod();
                   this.clickTag();

               })
            },
            clickTag(row){
                if (row){
                    this.tableId = row.brand_id;
                }
                let dt = {
                    thickness_id:this.thickness_id,
                    material_id:this.material_id,
                    brand_id:this.tableId,
                    status:this.formInline.status,
                    serach_brand_id:this.formInline.serach_brand_id,
                    page:this.page.page,
                    limit:this.page.pageSize
                };
                getTable(dt).then(res => {
                    this.brandAssociateStatus = res.result.brandAssociateStatus;
                    this.searchBrandList = res.result.searchBrandList;
                    this.constructList = res.result.constructList;
                    this.associateList = res.result.associateList.data;
                    this.page.totalItems = res.result.associateList.total;
                    this.thicknessList = res.result.thicknessList;
                    this.materialArr.push(res.result.material);
                    this.ruleForm1.material_id = res.result.material.id;
                })

            },
            closeTag(row){
                this.$confirm('确认删除该品牌?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    delList({id:row.id}).then(res => {
                        this.$message.success(res.msg);
                        if (parseInt(row.brand_id) == this.tableId){
                            this.tableId = '';
                        }
                        this.getList();
                    })
                })
            },
            addList(){
                if (this.searchId == ''){
                    return false;
                }
                let dt = {
                    brand_id:this.searchId,
                    material_id:this.material_id,
                    thickness_id:this.thickness_id
                }
                addList(dt).then(res => {
                    this.$message.success(res.msg);
                    this.searchId = '';
                    this.getList();
                })
            },
            getList(type){
                let dt = {
                    material_id:this.material_id,
                    thickness_id:this.thickness_id
                }
                getList31(dt).then(res => {
                    this.top_list = res.result.top_list;
                    this.search_list = res.result.search_list;
                    this.list = res.result.list;
                    if (type){
                        this.material_id = res.result.keel_id;
                    }
                })
            },
            indexMethod(index) {
                return this.page.pageSize*(this.page.page-1)+1+index;
            },
            closeDialod(){
                this.ruleForm1 = {
                    id: '',
                    type:"1",
                    material_id:this.ruleForm1.material_id,
                    construct_id:'',
                    beforeConstruct_id:'',
                    m_tid:'',
                    size:'',
                    start:'',
                    end:'',
                }
                this.dialogFormVisible = false;
            },
            handleSizeChange(size) {
                this.page.pageSize = size;
                this.clickTag();
            },
            handleCurrentChange(page) {
                this.page.page = page;
                this.clickTag();
            },
            valueChange1(e){
                let num = this.ruleForm1.size.indexOf('.');
                if (num > 0){
                    this.ruleForm1.size = this.ruleForm1.size.substr(0,num+3);
                }
            },
            valueChange2(e){
                let num = this.ruleForm1.start.indexOf('.');
                if (num > 0){
                    this.ruleForm1.start = this.ruleForm1.start.substr(0,num+3);
                }
            },
            valueChange3(e){
                let num = this.ruleForm1.end.indexOf('.');
                if (num > 0){
                    this.ruleForm1.end = this.ruleForm1.end.substr(0,num+3);
                }
            },
            inArr(arr,obj){
                var i = arr.length;
                while (i--) {
                    if (arr[i] === obj) {
                        return true;
                    }
                }
                return false;
            },
            addSpace(type,index){
                if (type == 1){
                    if (index){
                        this.ruleForm.grassroots.splice(index,1);
                        this.ruleForm.grassroots.forEach((element,index)=>{
                            this.changSelect(1,index,true);
                        })
                    }else {
                        this.ruleForm.grassroots.push({
                            grassroots_id:'',
                            grassroots_part_list: [],
                            construct_id:'',
                            format:'',
                        })
                    }
                }else if (type == 2){
                    if (index){
                        this.ruleForm.keel.splice(index,1);
                        this.ruleForm.keel.forEach((element,index)=>{
                            this.changSelect(2,index,true);
                        })
                    }else{
                        this.ruleForm.keel.push({
                            keel_id:'',
                            keel_part_list: [],
                            construct_id:'',
                            format:'',
                        })
                    }
                }else{
                    if (index){
                        this.ruleForm.structure.splice(index,1);
                        this.ruleForm.structure.forEach((element,index)=>{
                            this.changSelect(3,index,true);
                        })
                    }else{
                        this.ruleForm.structure.push({
                            structure_id:'',
                            structure_part_list: [],
                            construct_id:'',
                            format:'',
                        })
                    }
                }
            },
            cancel(){
                this.$emit('cancelFour');
            },
            init(){
                let dt = {
                    thickness_id:this.thickness_id,
                    brand_id:this.ruleForm.brand_id,
                }
                getList3(dt).then(res => {
                    this.ruleForm = {
                        id:'',
                        deploy_number:'',
                        thickness_id:'',
                        brand_id:'',
                        grassroots:[
                            {
                                grassroots_id:'',
                                grassroots_part_list:[],
                                construct_id:'',
                                format:'',
                            }
                        ],
                        keel:[
                            {
                                keel_id:'',
                                keel_part_list:[],
                                construct_id:'',
                                format:'',
                            }
                        ],
                        structure:[
                            {
                                structure_id:'',
                                structure_part_list:[],
                                construct_id:'',
                                format:'',
                            }
                        ],
                        thickness_name:'',
                    };
                    this.grassroots = res.result.grassroots_list;
                    this.keel = res.result.keel_list;
                    this.brandList = res.result.brandList;
                    this.structure = res.result.structure_list;
                    if (res.result.wildcard.deploy_number){
                        this.ruleForm = res.result.wildcard;
                    }else{
                        this.ruleForm.thickness_name = res.result.thickness_name;
                    }
                    this.ruleForm.grassroots.forEach((element,index)=>{
                        this.changSelect(1,index);
                    })
                    this.ruleForm.keel.forEach((element,index)=>{
                        this.changSelect(2,index);
                    })
                    this.ruleForm.structure.forEach((element,index)=>{
                        this.changSelect(3,index);
                    })
                    this.ruleForm.brand_id = res.result.defaultBrand.b_id;
                })
            },
            submit(formName){
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let dt = {
                            grassroots:_this.ruleForm.grassroots,
                            keel:_this.ruleForm.keel,
                            structure:_this.ruleForm.structure,
                            brand_id:_this.ruleForm.brand_id
                        };
                        if (_this.ruleForm.id){
                            dt.id = _this.ruleForm.id;
                        }else{
                            dt.thickness_id = _this.thickness_id;
                        }
                        edit3(dt).then(res => {
                            _this.$message.success(res.msg);
                            _this.init();
                            _this.$refs['ruleForm'].resetFields();
                        })
                    }
                })
            }

        },

    }
</script>
<style>
    .demo-input-label {
        display: inline-block;
        width: 150px;
    }
    .componentFour .el-radio-button__inner{
        min-width: 120px;
    }
    .componentFour .el-tag{
        margin-left: 0px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .componentFour .type2 .el-form-item{
        width: 100%;
    }

    .componentFour .specsInput .el-input__suffix-inner .el-input__icon:after{
        content: 'mm ' !important;
        width: 26px;
        line-height: 30px;
        height: 35px;
    }
    .componentFour .specsInput .el-icon-search:before{
        display: none;
    }

</style>