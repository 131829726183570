<template>
    <div class="history">
        <el-row style="padding: 25px">
            <ComponentOne v-if="showStatus == 1" @detailOne="detailOne"></ComponentOne>
            <ComponentTwo v-if="showStatus == 2" @detailTwo="detailTwo" @cancelTwo="cancelTwo" :setup_id="setup_id" ></ComponentTwo>
            <ComponentThree v-if="showStatus == 3" @detailThree="detailThree" @cancelThree="cancelThree" :setup_id="setup_id" :material_id="material_id"></ComponentThree>
<!--            <ComponentThree v-if="showStatus == 3" :threeEdit="threeEdit" @detailThree="detailThree" @cancelThree="cancelThree" :setup_id="setup_id" :material_id="material_id"></ComponentThree>-->
            <ComponentFour v-if="showStatus == 4" @cancelFour="cancelFour" :thickness_name="thickness_name" :thickness_id="thickness_id"></ComponentFour>
        </el-row>

    </div>
</template>
<script>
    import ComponentOne from "../../components/ComponentOne";
    import ComponentTwo from "../../components/ComponentTwo";
    import ComponentThree from "../../components/ComponentThree";
    import ComponentFour from "../../components/ComponentFour";
    export default {
        components:{
            ComponentOne,
            ComponentTwo,
            ComponentThree,
            ComponentFour
        },
        data () {
            return {
                showStatus:1,
                setup_id:'',
                material_id:'',
                thickness_id:'',
                thickness_name:'',
                // threeEdit:true,
            }
        },
        created() {

        },
        methods:{
            detailOne(row){
                this.setup_id = row.id;
                this.showStatus = 2;
            },
            cancelTwo(){
                this.setup_id = '';
                this.showStatus = 1;
            },
            detailTwo(row){
                this.material_id = row.id;
                // if(parseInt(this.confirmType.indexOf(row.material_name)) < 0){
                //     this.threeEdit = true;
                // }else{
                //     this.threeEdit = false;
                // }
                this.showStatus = 3;
            },
            cancelThree(){
                this.material_id = '';
                this.showStatus = 2;
            },
            detailThree(row){
                this.thickness_id = row.id;
                this.thickness_name = row.thickness_name;
                this.showStatus = 4;
            },
            cancelFour(){
                this.showStatus = 3;
            }


        },

    }
</script>
<style scoped>
    .demo-input-label {
        display: inline-block;
        width: 130px;
    }
</style>